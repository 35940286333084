<template>
  <el-container style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
    " class="logs2">
    <el-main style="flex-grow: 1; width: 100%">
      <div class="content_box" style="
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        ">
        <h1 style="margin-top: 0px; font-size: 20px; text-align: center">
          学习记录
        </h1>
        <el-table :show-header="false" :data="userData" border style="width: 100%; margin-top: 30px">
          <el-table-column width="150">
            <template slot-scope="{row}">
              {{ row[0] }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="{row}">
              {{ row[1] }}
            </template>
          </el-table-column>
        </el-table>
        <h1 style="margin-top: 0px; font-size: 20px; text-align: center;margin: 30px 0;">
          学习追溯
        </h1>
        <el-table :data="rightData" border style="
                width: 100%;
                margin-bottom: 30px;
                border-top-width: 0;
                flex-grow: 1;
                margin-top: 0px;
                border-top-width: 1px;
              ">
          <el-table-column type="index" label="序号" :index="indexMethod" align="center" />
          <el-table-column prop="timeInterval" label="学习时段" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.startTime }} 至 {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column prop="catalogName" label="课程名称" min-width="133">
          </el-table-column>
          <el-table-column label="学习时长" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.learningLime }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background small layout="prev, pager, next" :total="parseInt(apiData1.total)"
          :current-page="apiData1.current" @current-change="getRightData">
        </el-pagination>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "h5/learningRecord",
  data () {
    return {
      userData: [],
      rightData: [],
      info: {},
      apiData1: {
        total: 0,
        current: 1,
      },
      pageNum1: 1,
      pageSize: 10,
      keyMap: {
        'completeState': '学习状态',
        'idcard': '身份证号',
        'industryName': '行业类型',
        'mobile': '联系电话',
        'postName': '岗位类型',
        'studyDuration': '学习总时长',
        'trainTypeName': '培训类型',
        'userName': '姓名',
        'userSex': '性别',
      }
    };
  },
  mounted () {
    this.getApi();
    this.getRightData();
  },
  methods: {
    // 序号
    indexMethod (index) {
      return (this.pageNum1 - 1) * 10 + index + 1;
    },
    getApi () {
      let newJson = this.$route.query.json.replace('\r\n', "");
      this.$post("/project/user/retraces/info", {
        json: newJson,
      }, 3000, true, 8)
        .then((res) => {
          this.info = res.data;
          this.userData = Object.keys(res.data).map(key => [this.keyMap[key], res.data[key]]);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //学习追溯
    getRightData (pageNum = 1) {
      this.pageNum1 = pageNum;
      let newJson = this.$route.query.json.replace('\r\n', "");
      console.log(newJson)
      this.$post("/project/user/retraces/study/info", {
        json: newJson,
        pageNum: this.pageNum1,
        pageSize: 10,
      }, 3000, true, 8)
        .then((res) => {
          this.rightData = res.data.list;
          this.apiData1.total = res.data.total;
        })
        .catch((err) => {
          // this.getRightData();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.logs2 {
  .el-tabs__header {
    margin: 0;
  }

  /deep/ .el-tabs__item.is-active {
    font-size: 20px;
    color: #2c3e50;
    font-weight: bold;
  }
}

.table_h5 {
  td {
    border: 1px solid #ccc;
  }
}
</style>